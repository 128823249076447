<template>
    <Toast />
    <ConfirmDialog />

    <Toolbar>

        <template #start>
            <div class="inline sm:hidden">
                <div class="">
                    <SplitButton label="Neu" icon="pi pi-plus" @click="setNew" :model="items" />
                </div>
            </div>
            <div class="hidden sm:flex">
                <Button v-show="changes != 0" v-tooltip.bottom="'Änderungen zurücksetzen'" @click="reset_changes" class="mr-2 p-button-danger" icon="pi pi-refresh" />
                <Button v-show="changes > 0" @click="save_changes" v-tooltip.bottom="'Änderungen speichern'" :label="save_text" class="w-auto  p-button-success mx-2" icon="pi pi-save" />

                <Button v-show="changes == 0" @click="setNew" label="Erstellen" class="w-auto mr-2 p-button" v-tooltip.top="'Ein neuer Datensatz erfassen'" icon="pi pi-plus" />
            </div>
        </template>


    </Toolbar>
    <Toolbar class="mt-2">
        <template #start>
            <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filter_dataset['global'].value" placeholder="Suche"  class="mr-2 md:w-" />
            </span>
        </template>
    </Toolbar>
    <!--------------------------------------------------->
    <!------------------ DataTable ---------------------->
    <!--------------------------------------------------->
    <DataTable class="mt-3" v-model:filters="filter_dataset" :value="dataset" :rows="20" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[20, 50, 100]" @rowReorder="ondatasetRowReorder" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="{first} bis {last} von {totalRecords}">
        <Column field="level" header="Level" class="w-1/4"></Column>
        <Column field="name" header="Label" class="w-1/4">
            <template #body="slotProps">
                <span class="">{{ slotProps.data.name }}</span>
            </template>
        </Column>
        <Column class=" text-right">
            <template #body="slotProps">
                <div class="text-right">
                <Button v-if="force_save == false" @click="openEdit(slotProps.data.level), role_name = slotProps.data.name, role_level = slotProps.data.level, data_index = slotProps.index" class="p-button-rounded p-button-text" v-tooltip.top="'Datensatz bearbeiten'" icon="pi pi-pencil" />
                <Button v-if="force_save == false" @click="delete_dataset(slotProps.data.id)" class="p-button-rounded p-button-text" v-tooltip.top="'Datensatz löschen'" icon="pi pi-trash" />
                </div>
            </template>
        </Column>
    </DataTable>

    <div class="flex items-center justify-center m-16" v-show="loader">
        <LoadingSpinner size="12"></LoadingSpinner>
    </div>


    <!------------------- Dialogs ----------------------->
    <Dialog v-model:visible="editDialog" header="Datensatz bearbeiten" :modal="true" class="w-auto md:w-3/4">
        <div class="flex flex-col md:flex-row">
            <div class="w-full p-3">
                <div class="my-2">
                    <label for="name">Name</label>
                    <InputText v-model="role_name" id="name" class="w-full" />
                </div>
                <div class="my-2">
                    <label for="role_level">Level (Edit zur Zeit noch nicht möglich)</label>
                    <Dropdown v-model="role_level" id="role_level" class="w-full" :options="available_level" :disabled="true" />
                </div>
            </div>
        </div>
        <div class="p-3">
            <Button label="Speichern" @click="save_dataset(data_index), editDialog = false" class="w-auto mr-2 mb-1 p-button-success" icon="pi pi-save" />
            <Button label="Abbrechen" @click="editDialog = false" class="w-auto mr-3 mb-1 p-button-danger" icon="pi pi-times" />
        </div>

    </Dialog>

    <!------------------ New ---------------------->
    <Dialog v-model:visible="newDialog" header="Kategorie hinzufügen" :modal="true" class=" w-full mx-3 md:w-3/4">

        <div class="flex flex-col md:flex-row">
            <div class="w-full p-3">
                <div class="my-2">
                    <label for="name">Name</label>
                    <InputText v-model="role_name" id="name" class="w-full" />
                </div>
                <div class="my-2">
                    <label for="role_level">Level</label>
                    <Dropdown v-model="role_level" id="role_level" class="w-full" :options="filtered_levels" />
                </div>
            </div>
        </div>

        <div class="p-3">
            <Button :disabled="role_name == ''" label="Speichern" @click="new_dataset(), newDialog = false" class="w-auto mr-2 mb-1 p-button-success" icon="pi pi-save" />
            <Button label="Abbrechen" @click="newDialog = false" class="w-auto mr-3 mb-1 p-button-danger" icon="pi pi-times" />
        </div>

    </Dialog>
</template>


<script setup>
import { ref, onMounted, watch, inject } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import Simplestore from '@/core/var/Simplestore';
import LoadingSpinner from '@/core/components/UI/Loading-Spinner.vue';
import { FilterMatchMode } from 'primevue/api'
import { useCustomerStore } from '@/core/store/CustomerStore'
import { slugify, getRandomId, preview_dataset } from '@/core/var/tools'


const toast = useToast()
const dataset = ref([])
const role_name = ref(null)
const data_content = ref(null)
const role_level = ref();
const chatGptQuestions = ref([])
const chatGptDialog = ref(false)
const changes = ref(0)
const save_text = ref("Änderungen speichern")
const editDialog = ref(false)
const newDialog = ref(false)
const filtered_levels = ref()
const available_level = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
const force_save = ref(false)
const loader = ref(false)
const disabled = ref()
const status = ref([
    { label: 'Aktiv', value: 'published' },
    { label: 'Inaktiv', value: 'draft' }
])
const filter_dataset = ref({ 'global': { value: null, matchMode: FilterMatchMode.CONTAINS } })


const slug = ref(useCustomerStore().getCustomer.customer);

const module = '';

const endpoints = {
    get: `${module}get-roles`,
    save: `${module}set-roles`
}


onMounted(async () => {
    loader.value = true;
    await bootstrap().then(() => {
        loader.value = false;
    });
})


const bootstrap = async () => {
    try {
        const [dataResponse] = await Promise.all([
            axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/dashboard/${endpoints.get}`)
        ]);
        dataset.value = dataResponse.data;

        const level_to_filter = dataResponse.data.map(item => item.level);
        filtered_levels.value = available_level.filter(level => !level_to_filter.includes(level));

    } catch (error) {
        console.error(error);
    }
}

const items = ref([
    {
        label: save_text,
        icon: 'pi pi-refresh',
        disabled: changes.value > 0 ? false : true,
        command: () => {
            save_changes();
            //toast.add({ severity: 'success', summary: 'Updated', detail: 'Data Updated', life: 3000 });
        }
    },
    {
        label: 'Zurücksetzen',
        icon: 'pi pi-times',
        disabled: changes.value != 0 ? false : true,
        command: () => {
            reset_changes();
            //toast.add({ severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000 });
        }
    },
]);

watch(changes, res => {
    items.value[0].disabled = res > 0 ? false : true;
    items.value[1].disabled = res != 0 ? false : true;
});

watch(loader, res => {
    Simplestore.spinner = res;
    disabled.value = res;
});

const openEdit = (res) => { 
    editDialog.value = true;
}

const reset_changes = async () => {
    try {
        const response = await axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${slug.value}/${endpoints.get}`);
        dataset.value = response.data;
        changes.value = 0;
        save_text.value = "Änderungen speichern";
        force_save.value = false;
    } catch (error) {
        console.error(error);
    }
};

const ondatasetRowReorder = (e) => {
    dataset.value = e.value
    changes.value = changes.value + 1
    save_text.value = "Speichern (" + changes.value + ")"
    force_save.value = true
    toast.add({ severity: 'success', summary: 'Reihenfolge geändert', detail: 'Die Reihenfolge wurde erfolgreich geändert', life: 3000 })
}

const get_status = (status_value) => {
    for (let i = 0; i < status.value.length; i++) {
        if (status.value[i].value == status_value) {
            if (status_value == "published") {
                return "Aktiv"
            }
            if (status_value == "draft") {
                return "Inaktiv"
            }
        }
    }
}


const setNew = () => {
    newDialog.value = true;
    role_name.value = '';
}

const save_dataset = (index) => {
    dataset.value[index]["name"] = role_name.value
    dataset.value[index]["id"] = getRandomId(6)
    dataset.value[index]["level"] = role_level.value
    incrementSaves();

}

const new_dataset = () => {

    const new_data = {
        "id": getRandomId(6),
        "name": role_name.value,
        "level": role_level.value,
        "members": []
    }

    dataset.value.push(new_data);

    dataset.value.sort((a, b) => a.level - b.level);

    force_save.value = true
    incrementSaves();
}

const delete_dataset = (id) => {

    const newt = dataset.value.filter(dataset => dataset.id !== id);
    dataset.value = newt;

    incrementSaves();
}

const incrementSaves = () => {
    changes.value = changes.value + 1
    save_text.value = "Speichern (" + changes.value + ")"
}

const save_changes = async () => {
    try {
        await axios.post(
            `${process.env.VUE_APP_NEURAXIS_API_MAIN}/dashboard/${endpoints.save}`,
            dataset.value
        );
        toast.add({
            severity: "success",
            summary: "Änderungen gespeichert",
            detail: "Die Änderungen wurden erfolgreich gespeichert",
            life: 3000
        });
        changes.value = 0;
        save_text.value = "Änderungen speichern";
        force_save.value = false;
        bootstrap();
    } catch (error) {
        console.error(error);
    }
};

const GPT_Primer = (title) => {
    chatGptQuestions.value = [
        {
            question: `Erstelle einen FAQ-Antort auf die Frage zum Thema ${title}`,
            button_name: "Antwort automatisch erstellen",
        }
    ];
}

const update_text_content = res => {
    data_content.value = data_content.value + res.chatGptAnswers;
    chatGptDialog.value = false;
}
</script>
