<script setup>
import { ref, defineProps, defineEmits } from 'vue'
import MenuButton from './Components/Menu-Button.vue'
import DataView from './Components/Data-View.vue'
import ModalButtons from './Components/Modal-Menue.vue'
// local constants
const BLOCK_NAME = "cta_button";
const editDialog = ref(false)


// define props
const props = defineProps({
    template_type: { type: String, required: true },
    index: { type: Number, required: false },
    data: { type: Object, required: true },
    workflow_id: { type: String, required: false },
    image_library: { type: Object, required: false },
})
// define emits
const emits = defineEmits(['blockCallback'])

// fields
const content = ref(props.data.content)
const template = ref(BLOCK_NAME)
const target = ref(props.data.target)
const text_button_text = ref(props.data.text_button_text)

const button_url = ref(props.data.button_url)
const alignment = ref(props.data.alignment)
const targets = ref([
    { label: 'Im gleichen Fenster öffnen', value: '_self' },
    { label: 'In einem neuen Tab öffnen', value: '_blank' }
])
const alignments = ref([
    { label: 'Links', value: 'left' },
    { label: 'Rechts', value: 'right' },
    { label: 'Zentriert', value: 'center' }
])

// functions
const edit_block = () => {
    editDialog.value = true
}

const save_block = () => {
    emits('blockCallback',
        {
            "template_data": {
                "template": template.value,
                "text_content": content.value,
                "text_button_text": text_button_text.value,
                "button_url": button_url.value,
                "target": target.value,
                "alignment": alignment.value
            },
            "action": "save",
            "index": props.index
        }
    )
    editDialog.value = false
}

const delete_block = () => {
    emits('blockCallback',
        {
            "action": "delete",
            "index": props.index
        }
    )
    editDialog.value = false
}

const add_block = () => {
    emits('blockCallback',
        {
            "template_data": {
                "template": template.value,
                "text_content": "Einen Standard Titel",
                "text_button_text": "Button Text",
                "button_url": "https://",
                "target": "self",
                "alignment": "left"
            },
            "action": "add",
            "index": props.index
        }
    )
}

const open_url = (url) => {
    window.open(url, target.value)
}

const align_preview = (alignment) => {
    if (alignment == 'left') {
        return 'justify-start'
    } else if (alignment == 'right') {
        return 'justify-end'
    } else {
        return 'justify-center'
    }
}
const toggleDialog = () => {
    editDialog.value = false
}
</script>
<template>
    <!--------------------------------------------------->
    <!------------------ Menu View ---------------------->
    <!--------------------------------------------------->
    <MenuButton v-if="props.template_type == 'menu'" :add_block="add_block" button="pi-external-link" name="CTA Button" />

    <!--------------------------------------------------->
    <!------------------ Data View ---------------------->
    <!--------------------------------------------------->
    <DataView v-if="props.template_type == 'data'" :edit_block="edit_block" name="CTA Button">
        <Divider />
        <div class="flex p-2" :class="align_preview(alignment)">
            <Button class="w-auto mr-3" :label="text_button_text" @click="open_url(button_url)"></Button>
        </div>
    </DataView>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="editDialog" :style="{ width: '80%' }" header="Block bearbeiten" :modal="true" class="p-fluid">
        <div class="">
            <ModalButtons :saveBlock="save_block" :deleteBlock="delete_block" :toggleDialog="toggleDialog" />
                 </div>
            <div>   
            <div class="my-3">
                <label for="content">Button Text</label>
                <InputText v-model="text_button_text" id="content" type="text" />
            </div>
            <div class="my-3">
                <label for="button_url">Button URL</label>
                <InputText v-model="button_url" id="button_url" type="text" />
            </div>
            <div class="my-3">
                <label for="content">Verhalten</label>
                <Dropdown v-model="target" :options="targets" optionLabel="label" optionValue="value" />
            </div>
            <div class="my-3">
                <label for="content">Ausrichtung</label>
                <Dropdown v-model="alignment" :options="alignments" optionLabel="label" optionValue="value" />
            </div>
        </div>
    </Dialog>
</template>