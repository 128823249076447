<template>
  <div>
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog as="div" class="relative z-40 md:hidden" @close="setSidebar(false)">
        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>

        <div class="fixed inset-0 z-40 flex">
          <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
            <DialogPanel class="relative flex w-full max-w-xs flex-1 flex-col bg-white pt-5 pb-4">
              <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">

                <div class="absolute top-2/4 right-12 -mr-12 pt-2">

                  <!-- <button type="button" class="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" @click="setSidebar(false)">
                    <span class="sr-only">Close sidebar</span>
                    <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                  </button> -->
                  <button class="round-button">
                    <ChevronLeftIcon class="h-4 w-4" @click="setSidebar(false)" />
                  </button>
                </div>
              </TransitionChild>
              <div class="flex flex-shrink-0 items-center justify-between pr-5">
                <div>
                  <profile-badge @sidebarOpen="setSidebar" />
                </div>
                <div>
                  <router-link to="/logout">
                    <img src="../assets/v2/logout.svg" alt="">
                  </router-link>
                </div>
              </div>
              <div class="mt-5 h-0 flex-1 overflow-y-auto">
                <nav class="space-y-1 px-2">
                  <MainNavigation @sidebarOpen="setSidebar" />
                </nav>

              </div>
            </DialogPanel>
          </TransitionChild>
          <div class="w-14 flex-shrink-0" aria-hidden="true">
            <!-- Dummy element to force sidebar to shrink to fit close icon -->
          </div>
        </div>
      </Dialog>
    </TransitionRoot>


    <!-- Static sidebar for desktop -->
    <!-- closed: md:w-12  -->
    <div class="hidden md:fixed md:inset-y-0 md:flex md:flex-col bg-[#fbfbfb] ease-out duration-200" :class="leftbarStatus" id="sidebar" ref="sidebar">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex min-h-0 flex-1 flex-col ">
        <div class="flex h-16 flex-shrink-0 items-center ml-3 w-5 border-b border-[#e7e7e7]" v-if="sidebarStatus">
          <router-link to="/dashboard" exact-active-class="none">
            <img class="h-8 w-auto  " src="../assets/v2/Snappy_v2_logo_nofont.svg" alt="" />
          </router-link>
        </div>
        <div class="flex h-16 flex-shrink-0 items-center bg-[#fbfbfb] px-4 border-b border-[#e7e7e7]" v-else>
          <router-link to="/dashboard" exact-active-class="none">
            <img class="h-8 w-auto " src="../assets/v2/Snappy_v2_logo.svg" alt="" />
          </router-link>
        </div>

        <div class="flex flex-1 flex-col overflow-y-auto overflow-x-hidden md:border-r-2 border-[#e7e7e783]">
          <nav class="flex-1">
            <div class="py-5 px-1">
              <button class="round-button" @click="changeSidebarStatus()">
                <ChevronRightIcon class="h-4 w-4" v-if="sidebarStatus" />
                <ChevronLeftIcon class="h-4 w-4" v-else />
              </button>
              <MainNavigation :big="sidebarStatus" />
            </div>
          </nav>
          <Infobox v-if="!sidebarStatus" />

          <div v-if="!sidebarStatus">
            <!-- <div class="text-xs font-semibold leading-6 text-gray-400 ml-1">Snappy.works</div> -->
            <nav class="">
              <div v-for="link in secondnavi" :key="link.name">
                <!-- Tmp, do not delete! -->
                <!-- <router-link exact-active-class="none" :to="link.href" :class="[link.current ? 'bg-gray-50 text-primary-600' : 'text-gray-700 hover:text-primary-600 hover:bg-gray-50', 'group flex gap-x-3 rounded-md p-2 text-sm font-medium bg-white']">
                  <component :is="link.icon" :class="[link.current ? 'text-primary-600' : 'text-gray-400 group-hover:text-primary-600', 'h-6 w-6 shrink-0']" aria-hidden="true" />
                  <span class="truncate">{{ link.name }}</span>
                </router-link> -->
                <!-- Tmp! -->
                <a href="#!" :class="[link.current ? 'bg-gray-50 text-primary-600' : 'text-gray-700 hover:text-primary-600 hover:bg-gray-50', 'group flex gap-x-3 rounded-md p-2 text-sm font-medium bg-transparent']" @click="toggle">
                  <component :is="link.icon" :class="[link.current ? 'text-primary-600' : 'text-gray-400 group-hover:text-primary-600', 'h-6 w-6 shrink-0']" aria-hidden="true" />
                  <span class="truncate mt-0.5">{{ link.name }}</span>
                </a>
              </div>


              <OverlayPanel ref="op" appendTo="body" :showCloseIcon="true" id="overlay_panel" class=" w-40">
                <div>
                  <div class="my-1"><img class="h-8 w-auto " src="../assets/v2/Snappy_v2_logo.svg" alt="Snappy" /></div>
                  <div class="my-1">Coming soon!</div>
                </div>
              </OverlayPanel>

            </nav>
          </div>

        </div>
      </div>
    </div>

    <!-- closed: md:pl-12  -->
    <div class="flex flex-col h-screen ease-in duration-200" :class="middleStatus" id="middle" ref="middle">
      <div class="sticky top-0 z-10 flex h-16 flex-shrink-0 bg-[#fbfbfb] border-b border-[#e7e7e7]">
        <button type="button" class="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500 md:hidden" @click="sidebarOpen = true">
          <span class="sr-only">Open sidebar</span>
          <Bars3BottomLeftIcon class="h-6 w-6" aria-hidden="true" />
        </button>

        <div class="flex flex-1 justify-between px-0 md:px-4">

          <div class=""></div>
          <div class="ml-4 flex items-center md:ml-6">

            <div>
              <LoadingSpinner v-show="Simplestore.spinner"></LoadingSpinner>
            </div>

            <!-- Profile dropdown -->
            <div>
              <profile-badge class="hidden md:inline-block mx-5" @sidebarOpen="setSidebar" />
              <!-- <Menu as="div" class="relative mx-5 hidden md:inline-block">
                <div>
                  <MenuButton class="bg-[#F5F7FF] p-2  m-4 flex max-w-xs items-center rounded-2xl text-sm focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2">
                    <main-avatar :status="adminstatus" />

                    <div class=" text-start mx-2 text-xs">
                      <div class=" font-normal">{{ auth.userdata.user_firstname }} {{ auth.userdata.user_lastname }}</div>
                      <div class=" font-medium">{{ appmode.fullname }}</div>
                    </div>

                    <ChevronDownIcon class="h-4 w-4" />
                    <span class="sr-only">User Menü öffnen</span>
                  </MenuButton>
                </div>

                <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                  <MenuItems class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <MenuItem v-for="item in userNavigation" :key="item.name" v-slot="{ active }">
                    <router-link :to="item.href" active-class="bg-gray-200" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">{{ item.name }}</router-link>
                    </MenuItem>
                  </MenuItems>
                </transition>

              </Menu> -->
              <div class="logo md:hidden">
                <router-link to="/dashboard" exact-active-class="none">
                  <img class="h-8 w-auto " src="../assets/v2/Snappy_v2_logo.svg" alt="" />
                </router-link>
              </div>
            </div>

            <!-- Logout -->
            <div class="w-1/4">
              <div class="hidden md:block">
                <router-link to="/logout">
                  <div class="flex">
                    <div class=""><img src="../assets/v2/logout.svg" alt=""></div>
                    <div class="mx-1 font-light">Logout</div>
                  </div>
                </router-link>
              </div>
            </div>

          </div>
        </div>
      </div>

      <main class="flex-1">
        <div class="py-6">
          <div class="mx-auto px-4 sm:px-6 md:px-8">
            <router-view />
          </div>
        </div>
      </main>

      <!-- Footer -->
      <footer class=" bg-[#F5F7FF] font-light text-gray-300">
        <div class="flex justify-between items-center text-sm w-full">

          <div class="hidden md:block w-1/2 p-5">Snappy.works
            <div v-if="adminstatus">Dashboardversion <span class=" font-semibold">Neuraxis {{ dashboard_version }}</span></div>
          </div>

          <div class="w-full md:w-1/2 p-5 navigation">
            <nav class="flex items-center space-x-4 justify-center md:justify-end">
              <div class="pr-2 border-r"><a href="https://snappy.works" target="_blank">snappy.works</a></div>
              <div class="pr-2 border-r"><a href="https://snappy.works/terms" target="_blank">Our Terms</a></div>
              <div><a href="https://snappy.works/" target="_blank">Privacy police</a></div>
            </nav>
          </div>
        </div>
      </footer>
      <!-- Footer ende -->

    </div>
    <!-- Shell ende -->
  </div>
</template>

<script setup>
import LoadingSpinner from './UI/Loading-Spinner.vue';
import { ref, onBeforeMount } from 'vue';

import {
  Dialog,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue'

import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  XMarkIcon,
  Bars3BottomLeftIcon,
  CodeBracketIcon,
  ChatBubbleLeftIcon, InformationCircleIcon
} from '@heroicons/vue/24/outline'

import Simplestore from '@/core/var/Simplestore'
import { useCustomerStore } from '@/core/store/CustomerStore';
import { useAuthStore } from '@/core/store/AuthStore';
import MainNavigation from '@/core/components/UI/Main-Navigation.vue';
import Infobox from '@/core/components/UI/Main-Infobox.vue';
import ProfileBadge from '@/core/components/Main-Profile-Badge.vue';

const auth = useAuthStore();

const adminstatus = ref(auth.getAuth(0));
const customerStore = useCustomerStore();
const appmode = customerStore.getCustomer;
const dashboard_version = appmode.dashboardversion;

//initial sitebar state after login
const sideBarInit = 'true'
const sidebarStatus = ref(false)

const leftbarStatus = ref(['md:w-64'])
const middleStatus = ref(['md:pl-64'])
const sidebarOpen = ref(false);

const changeSidebarStatus = (force = null) => {

  if (force !== null) {
    sidebarStatus.value = force
  }
  sidebarStatus.value = sidebarStatus.value == true ? false : true
  leftbarStatus.value = sidebarStatus.value ? ['md:w-12'] : ['md:w-52']
  middleStatus.value = sidebarStatus.value ? ['md:pl-12'] : ['md:pl-52']

  localStorage.setItem('sidebarStatus', sidebarStatus.value)
}


onBeforeMount(() => {

  let item = localStorage.getItem('sidebarStatus')

  if (item === null) {
    item = sideBarInit
  }
  const booleanItem = (item === 'true') ? false : true;

  changeSidebarStatus(booleanItem);
})


const setSidebar = res => {
  sidebarOpen.value = res;
}


const secondnavi = [
  { id: 1, name: 'Snappy Apps', href: '#', icon: CodeBracketIcon, current: false },
  { id: 2, name: 'News', href: '#', icon: InformationCircleIcon, current: false },
  { id: 3, name: 'Support', href: '#', icon: ChatBubbleLeftIcon, current: false },
]
const op = ref();
const toggle = (event) => {
  op.value.toggle(event);
}
</script>

<style scoped>
.round-button {
  @apply bg-gray-100 rounded-full shadow absolute right-0 top-1/2 -mr-4 h-8 w-8 text-center flex items-center justify-center transition duration-200 ease-in-out transform hover:bg-[#dedede96]
}

.slide-enter-active, .slide-leave-active {
  transition: transform 0.3s;
}

.slide-enter,
.slide-leave-to

/* .slide-leave-active in <2.6 */
  {
  transform: translateX(-100%);
}</style>