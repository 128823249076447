<template>
    <Toast />
    <ConfirmDialog />

    <Toolbar>

        <template #start>
            <div class="inline sm:hidden">
                <div class="">
                    <SplitButton label="Neu" icon="pi pi-plus" @click="setNew" :model="items" />
                </div>
            </div>
            <div class="hidden sm:flex">
                <Button v-show="changes != 0" v-tooltip.bottom="'Änderungen zurücksetzen'" @click="reset_changes" class="mr-2 p-button-danger" icon="pi pi-refresh" />
                <Button v-show="changes > 0" @click="save_changes" v-tooltip.bottom="'Änderungen speichern'" :label="save_text" class="w-auto  p-button-success mx-2" icon="pi pi-save" />

                <Button @click="setNew" label="Erstellen" class="w-auto mr-2 p-button" v-tooltip.top="'Ein neuer Datensatz erfassen'" icon="pi pi-plus" />
            </div>
        </template>


    </Toolbar>
    <Toolbar class="mt-2">
        <template #start>
            <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filter_dataset['global'].value" placeholder="Suche" style="width: 400px" class="mr-2" />
            </span>
        </template>
    </Toolbar>
    <!--------------------------------------------------->
    <!------------------ DataTable ---------------------->
    <!--------------------------------------------------->
    <DataTable class="mt-3" v-model:filters="filter_dataset" :value="team" :rows="20" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[20, 50, 100]" @rowReorder="onTeamRowReorder" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
        <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
        <Column field="status" header="Status">
            <template #body="{ data, field }">
                <span class="inline-flex items-center rounded-full bg-yellow-200 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/10" v-if="data[field] == 'draft'" :label="get_status(data[field])" v-tooltip.top="'Beitrag ist Publiziert'">{{ get_status(data[field]) }}</span>

                <span class="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-900 ring-1 ring-inset ring-green-600/20" v-if="data[field] == 'published'" v-tooltip.top="'Beitrag ist Publiziert'">{{ get_status(data[field]) }}</span>
            </template>
        </Column>
        <Column field="name" header="Kategorie">
            <template #body="slotProps">
                <span class="">{{ slotProps.data.name }}</span>
            </template>
        </Column>
        <Column>
            <template #body="slotProps">
                <Button v-if="force_save == false"
                    @click="editDialog = true, edit_image = false, edit_avatar = false, category_name = slotProps.data.name,  data_status = slotProps.data.status, data_index = slotProps.index"
                    class="p-button-rounded p-button-text" v-tooltip.top="'Datensatz bearbeiten'" icon="pi pi-pencil" />
                <Button v-if="force_save == false" @click="delete_dataset(slotProps.data.value)" class="p-button-rounded p-button-text" v-tooltip.top="'Datensatz löschen'" icon="pi pi-trash" />
            </template>
        </Column>
    </DataTable>

    <div class="flex items-center justify-center m-16" v-show="loader">
        <LoadingSpinner size="12"></LoadingSpinner>
    </div>


    <!------------------- Dialogs ----------------------->
    <Dialog v-model:visible="editDialog" header="Datensatz bearbeiten" :modal="true" class="w-auto md:w-3/4">
        <div class="flex flex-col md:flex-row">
            <div class="w-full p-3">
                <div class="my-2">
                    <label for="name">Kategorie</label>
                    <InputText v-model="category_name" id="name" class="w-full" />
                </div>
                <div class="my-2">
                    <Dropdown v-model="data_status" id="status" :options="status" optionLabel="label" optionValue="value" />
                </div>
            </div>
        </div>
        <div class="p-3">
            <Button label="Speichern" @click="save_dataset(data_index), editDialog = false" class="w-auto mr-2 mb-1 p-button-success" icon="pi pi-save" />
            <Button label="Abbrechen" @click="editDialog = false" class="w-auto mr-3 mb-1 p-button-danger" icon="pi pi-times" />
        </div>

    </Dialog>

    <!------------------ New ---------------------->
    <Dialog v-model:visible="newDialog" header="Kategorie hinzufügen" :modal="true" class=" w-full mx-3 md:w-3/4">

        <div class="flex flex-col md:flex-row">
            <div class="w-full p-3">
                <div class="my-2">
                    <label for="name">Kategorie</label>
                    <InputText v-model="category_name" id="name" class="w-full" />
                </div>
            </div>
        </div>

        <div class="p-3">
            <Button :disabled="category_name == ''" label="Speichern" @click="new_dataset(), newDialog = false" class="w-auto mr-2 mb-1 p-button-success" icon="pi pi-save" />
            <Button label="Abbrechen" @click="newDialog = false" class="w-auto mr-3 mb-1 p-button-danger" icon="pi pi-times" />
        </div>

    </Dialog>

    <Dialog v-model:visible="imageUploadDialog" :style="{ width: '800px' }" header="Bilder hochladen" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-3">
                <FileUpload name="demo[]" :customUpload="true" @uploader="upload_library_image" :multiple="true" accept="image/*" :maxFileSize="10000000" chooseLabel="Bilder auswählen" uploadLabel="Hochladen" cancelLabel="Abbrechen">
                    <template #empty>
                        <div>
                            <div class="flex justify-content-center"><i class="pi pi-upload" style="font-size: 4rem; color: #dee2e6;"></i></div>
                            <div class="flex justify-content-center mt-2">Hochladen per Drag & Drop</div>
                        </div>
                    </template>
                </FileUpload>
            </div>
        </div>
    </Dialog>

    <Dialog v-model:visible="chatGptDialog" :style="{ width: '80%' }" header="Inhalte mit AI erstellen" :modal="true" class="p-fluid">
        <ChatGPT :questions="chatGptQuestions" @chat-callback="update_text_content" />
    </Dialog>
</template>


<script setup>
import { ref, onMounted, watch, inject } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import Simplestore from '@/core/var/Simplestore';
import LoadingSpinner from '@/core/components/UI/Loading-Spinner.vue';
import { FilterMatchMode } from 'primevue/api'
import ChatGPT from '@/core/components/Components/Main-GPT.vue';
import { useCustomerStore } from '@/core/store/CustomerStore'
import { slugify } from '@/core/var/tools'


const toast = useToast()
const SERVICE_URL = inject('SERVICE_URL');
const team = ref([])
const category_name = ref(null)
const data_role = ref(null)
const data_status = ref(null)
const data_captions = ref([]);

const data_content = ref(null)
const data_workflow_id = ref(null)
const chatGptQuestions = ref([])
const chatGptDialog = ref(false)
const changes = ref(0)
const save_text = ref("Änderungen speichern")
const editDialog = ref(false)
const newDialog = ref(false)
const imageUploadDialog = ref(false)
const image_library = ref([])
const edit_image = ref(false)
const edit_avatar = ref(false)
const force_save = ref(false)
const loader = ref(false)
const disabled = ref()
const status = ref([
    { label: 'Aktiv', value: 'published' },
    { label: 'Inaktiv', value: 'draft' }
])
const filter_dataset = ref({ 'global': { value: null, matchMode: FilterMatchMode.CONTAINS } })


const slug = ref(useCustomerStore().getCustomer.customer);

const module = 'products';

const endpoints = {
    get: `${module}/categories/get`,
    getImagelib: `${module}/get-image-library`,
    uploadImage: `${module}/upload-image`,
    uploadLibImage: `${module}/upload-library-image`,
    getsystem: "get-system",
    gettags: `${module}/tags`,
    getusers: "get-users",
    save: `${module}/categories/save`
}


onMounted(async () => {
    loader.value = true;
    await bootstrap().then(() => {
        loader.value = false;
    });
})


const bootstrap = async () => {
    try {
        const [dataResponse] = await Promise.all([
            axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${slug.value}/${endpoints.get}`)
        ]);
        team.value = dataResponse.data;
        image_library.value = false;
    } catch (error) {
        console.error(error);
    }
}


const items = ref([
    {
        label: save_text,
        icon: 'pi pi-refresh',
        disabled: changes.value > 0 ? false : true,
        command: () => {
            save_changes();
        }
    },
    {
        label: 'Zurücksetzen',
        icon: 'pi pi-times',
        disabled: changes.value != 0 ? false : true,
        command: () => {
            reset_changes();
        }
    },
]);

watch(changes, res => {
    items.value[0].disabled = res > 0 ? false : true;
    items.value[1].disabled = res != 0 ? false : true;
});

watch(loader, res => {
    Simplestore.spinner = res;
    disabled.value = res;
});

const reset_changes = async () => {
    try {
        const response = await axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${slug.value}/${endpoints.get}`);
        team.value = response.data;
        changes.value = 0;
        save_text.value = "Änderungen speichern";
        force_save.value = false;
    } catch (error) {
        console.error(error);
    }
};

const onTeamRowReorder = (e) => {
    team.value = e.value
    changes.value = changes.value + 1
    save_text.value = "Speichern (" + changes.value + ")"
    force_save.value = true
    toast.add({ severity: 'success', summary: 'Reihenfolge geändert', detail: 'Die Reihenfolge wurde erfolgreich geändert', life: 3000 })
}

const get_status = (status_value) => {
    for (let i = 0; i < status.value.length; i++) {
        if (status.value[i].value == status_value) {
            if (status_value == "published") {
                return "Aktiv"
            }
            if (status_value == "draft") {
                return "Inaktiv"
            }
        }
    }
}


const setNew = () => {
    newDialog.value = true;
    category_name.value = '';
}

const save_dataset = (index) => {
    team.value[index]["name"] = category_name.value
    team.value[index]["status"] = data_status.value
    team.value[index]["value"] = slugify(category_name.value)
    incrementSaves();
}

const new_dataset = () => {

    const slug = slugify(category_name.value);
    const new_data = {
        "name": category_name.value,
        "value": slug,
        "status": "draft",
    }

    team.value.unshift(new_data)

    force_save.value = true
    incrementSaves();
}

const delete_dataset = (id) => {
    const newt = team.value.filter(dataset => dataset.value !== id);
    team.value = newt;
    incrementSaves();
}

const incrementSaves = () => {
    changes.value = changes.value + 1
    save_text.value = "Speichern (" + changes.value + ")"
}

const save_changes = async () => {
    try {
        const response = await axios.post(
            `${process.env.VUE_APP_NEURAXIS_API_MAIN}/${slug.value}/${endpoints.save}`,
            team.value
        );
        toast.add({
            severity: "success",
            summary: "Änderungen gespeichert",
            detail: "Die Änderungen wurden erfolgreich gespeichert",
            life: 3000
        });
        changes.value = 0;
        save_text.value = "Änderungen speichern";
        force_save.value = false;
    } catch (error) {
        console.error(error);
    }
};

const GPT_Primer = (title) => {
    chatGptQuestions.value = [
        {
            question: `Erstelle einen FAQ-Antort auf die Frage zum Thema ${title}`,
            button_name: "Antwort automatisch erstellen",
        }
    ];
}

const update_text_content = res => {
    data_content.value = data_content.value + res.chatGptAnswers;
    chatGptDialog.value = false;
}
</script>
