import {
    Bars3Icon,
    CalendarIcon,
    HomeIcon,
    ArrowUpIcon,
    ArrowUpOnSquareIcon,
    MagnifyingGlassCircleIcon,
    MapIcon,
    MegaphoneIcon,
    UserGroupIcon,
    XMarkIcon,
    InboxIcon,
    UsersIcon,
    CogIcon,
    DocumentIcon,
    QuestionMarkCircleIcon,
    ArrowLeftOnRectangleIcon,
    ArrowDownTrayIcon,
    EnvelopeIcon,
    BellIcon,
    EnvelopeOpenIcon, MagnifyingGlassIcon, Bars3BottomLeftIcon, BookOpenIcon, ClipboardDocumentIcon, CloudIcon,
} from '@heroicons/vue/24/outline';

export default [
    { name: 'Dashboard', href: '/dashboard', icon: HomeIcon, current: false, order: 0 },
    { name: 'Stammdaten', href: '/dashboard/Stammdaten', icon: CogIcon, current: false, order: 1 },
    { name: 'Einstellungen', href: '/dashboard/Einstellungen', icon: CogIcon, current: false, order: 6 }
]
;