<template>
    <img class="inline-block h-8 w-8 rounded-full" :src="user_avatar" v-if="user_avatar" alt="" />
    <span class="inline-block h-8 w-8 overflow-hidden rounded-full  border-2" :class="mainclasses" v-else>
        <svg class="h-full w-full" fill="currentColor" viewBox="0 0 24 24" :class="svgclasses">
            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
        </svg>
    </span>
</template>
<script setup>
import { ref, defineProps, onMounted } from 'vue';
import { useAuthStore } from '@/core/store/AuthStore';
const props = defineProps({
    status: Boolean
})
const mainclasses = ref('bg-gray-100 border-gray-500');
const svgclasses = ref('text-gray-500');
const auth = useAuthStore();
const userdata = auth.getUserData;
const user_avatar = ref();

onMounted(() => {
    if (userdata.user_avatar) {
        user_avatar.value = userdata.user_avatar
    }
})

if (props.status == true) {
    mainclasses.value = 'bg-gray-100 border-gray-500';
    svgclasses.value = 'text-gray-500'
}

</script>