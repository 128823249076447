<template>
    <div class="px-4 my-6 bg-gray-100 py-2 rounded-md w-1/2" v-if="props.imagelibrary.length > 0">
        <div v-if="image">
            <img class="inline-block h-16 w-16 rounded-md cursor-pointer" :src="image" alt="" v-tooltip.bottom="'Bild editieren'" @click="edit = true" />
        </div>
        <ImagePlaceholder v-else class="h-16 w-16 block cursor-pointer hover:opacity-90" @click="edit = true" v-tooltip.bottom="'Bild hochladen'" />
        <figcaption class="mt-1 text-sm text-start text-gray-500 dark:text-gray-400">{{ props.label }}</figcaption>
    </div>

    <Dialog v-model:visible="edit" :style="{ width: '80%' }" header="Bild editieren" :modal="true" class="">
        <ImageEditor :edit="edit" :image_library="imagelibrary" :image="image" @update="header_image" :stencil-props="props.aspectRatio" @delete="delete_image" />
    </Dialog>
</template>
<script setup>
import { defineProps, ref, watch, defineEmits, inject, computed } from 'vue';
import { uploadSingleImage } from '@/core/var/crud'
import ImagePlaceholder from '../UI/Main-Image-Placeholder.vue';
import ImageEditor from '@/core/components/Components/Builder-Blocks/Components/Image-Editor.vue'

const props = defineProps({
    imagelibrary: Object,
    image: String,
    label: String,
    workflow_id: String,
    aspectRatio: Object,
    imageType: String,
})
const openDialog = ref(false);
const emits = defineEmits(['openDialog', 'uploadedImage', 'delete'])
const edit = ref(false);
const channel = ref('dashboard')
const endpoint = inject('upload_endpoint')
//const image_cropper = ref();
const edit_image = ref(props.image);

const workflow_id = computed(() => props.workflow_id)

watch(edit_image, res => console.log(res))

watch(openDialog, res => {
    emits('openDialog', res);
})

const header_image = res => {
    cropImage(res, props.imageType)
    edit.value = false;
}
const delete_image = () => {
    emits('delete', {type:props.imageType})
    edit.value = false;
}
// const setEdit = (img) => {
//     edit_image.value = img;
// }

const UploadImage = (image, image_type) => {

    const uploadchannel = channel.value;
    return new Promise((resolve, reject) => {
        uploadSingleImage(image, image_type, workflow_id.value, uploadchannel, endpoint, true).then((res) => {
            resolve(res.data.image_url);
        }).catch(err => {
            reject(err);
        });
    });
}

const cropImage = async (image, image_type) => {
    UploadImage(image, image_type).then(res => {
        const ret = { image: res, type: props.imageType }
        emits('uploadedImage', ret);
        edit.value = false;
    });
}

</script>