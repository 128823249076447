import { getRandomId } from '@/core/var/tools'
import axios from 'axios';

const uploadSingleImage = async (image, imageType, workflow_id, channel, path, random = false) => {

    const { canvas } = image.getResult();
    const blob = await new Promise((resolve) => canvas.toBlob(resolve));
    const reader = new FileReader();
    const randomNum = random === true ? `_${getRandomId(6)}` : null;


    if (!channel) {
        console.error('uploadImage() Error: Channel must be set.');
        throw new Error('Channel must be set.');
    }

    if (!path) {
        console.error('uploadImage() Error: Path must be set.');
        throw new Error('Path must be set.');
    }

    if (!workflow_id) {
        console.error('uploadImage() Error: workflow_id must be set.');
        throw new Error('workflow_id must be set.');
    }

    return new Promise((resolve, reject) => {

        const uploadURL = `${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel}/${path}`
  
        reader.onload = async () => {
            const payload = {
                file: reader.result,
                image_type: imageType + randomNum,
                workflow_id: workflow_id,
            };
            try {
                const response = await axios.post(
                    uploadURL,
                    payload
                );
                resolve(response);
            } catch (error) {
                reject(error);
                console.error(error);
            }
        };

        reader.readAsDataURL(blob);
    });
};
export { uploadSingleImage }