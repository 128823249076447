<script setup>
import { ref, defineProps, defineEmits } from 'vue'
import VueMarkdown from 'markdown-vue'
import MenuButton from './Components/Menu-Button.vue'
import ModalButtons from './Components/Modal-Menue.vue'
import DataView from './Components/Data-View.vue'
import { isValidURL } from '@/core/var/tools'

// local variables
const editHtmlDialog = ref(false)
const BLOCK_NAME = "markdown";
const NAME = "Markdown";

// define props
const props = defineProps({
    template_type: { type: String, required: true },
    index: { type: Number, required: false },
    data: { type: Object, required: true },
    image_library: { type: Object, required: false },
    workflow_id: { type: String, required: false },
})
// define emits
const emits = defineEmits(['blockCallback'])

// fields
const text_markdown = ref(props.data.text_markdown)
const template = ref(BLOCK_NAME)

// functions
const edit_block = () => {
    editHtmlDialog.value = true
}

const save_block = () => {
    emits('blockCallback',
        {
            "template_data": {
                "template": template.value,
                "text_markdown": text_markdown.value,
            },
            "action": "save",
            "index": props.index
        }
    )
    editHtmlDialog.value = false
}

const delete_block = () => {
    emits('blockCallback',
        {
            "action": "delete",
            "index": props.index
        }
    )
    editHtmlDialog.value = false
}

const add_block = () => {
    emits('blockCallback',
        {
            "template_data": {
                "template": template.value,
                "text_markdown": "",
            },
            "action": "add",
            "index": props.index
        }
    )
}

const toggleDialog = () =>{
    editHtmlDialog.value = false
}
function convertYouTubeURL(url) {
    const baseEmbedURL = 'https://www.youtube.com/embed/';
    const videoId = url.split('v=')[1]; 
    const ampersandPosition = videoId.indexOf('&');
    if (ampersandPosition != -1) {
        return baseEmbedURL + videoId.substring(0, ampersandPosition);
    }
    return baseEmbedURL + videoId;
}

</script>

<template>
    <!--------------------------------------------------->
    <!------------------ Menu View ---------------------->
    <!--------------------------------------------------->
    <MenuButton v-if="props.template_type == 'menu'" :add_block="add_block" button="pi-video" :name="NAME" />

    <!--------------------------------------------------->
    <!------------------ Data View ---------------------->
    <!--------------------------------------------------->
    <DataView v-if="props.template_type == 'data'" :edit_block="edit_block" :name="NAME">
        <div v-if="isValidURL(text_markdown)" class="flex justify-center items-center p-6 m-4">
        <iframe width="560" height="315" :src="convertYouTubeURL(text_markdown)" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
        <div v-else class="min-h-24 p-3 markdown-block">
            <VueMarkdown :source="text_markdown"  />
        </div>
    </DataView>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="editHtmlDialog" :style="{ width: '80%' }" header="Block bearbeiten" :modal="true" class="p-fluid">
        <div class="">
            <ModalButtons :saveBlock="save_block" :deleteBlock="delete_block" :toggleDialog="toggleDialog" />
            <div class="my-3">
                <Textarea v-model="text_markdown" class="w-full h-auto" placeholder="Emphasis, aka italics, with *asterisks* or _underscores_.

Strong emphasis, aka bold, with **asterisks** or __underscores__.

Combined emphasis with **asterisks and _underscores_**.

Strikethrough uses two tildes. ~~Scratch this.~~

[I'm an inline-style link](https://www.google.com)

[I'm an inline-style link with title](https://www.google.com 'Googles Homepage')
" />
            </div>
        </div>
    </Dialog>
</template>

<style>
.markdown-block h1{
    @apply font-bold leading-tight text-gray-900 text-3xl
}
.markdown-block h2{
    @apply font-bold leading-tight text-gray-900 text-2xl
}
.markdown-block h3{
    @apply font-bold leading-tight text-gray-900 text-xl
}

.markdown-block a{
    @apply text-primary-500 hover:underline
}
</style>