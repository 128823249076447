<template>
    <form class="space-y-8 divide-y divide-gray-200" @submit.prevent="AddUser">
        <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">

            <div class="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
                <div>
                    <h3 class="text-lg font-medium leading-6 text-gray-900">Persönliche Informationen</h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">Bitte nutzen Sie eine Adresse, mit der Sie täglich Mails abrufen</p>
                </div>
                <div class="space-y-6 sm:space-y-5">
                    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="firstname" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Vorname</label>
                        <div class="mt-1 sm:col-span-2 sm:mt-0">
                            <input type="text" name="firstname" id="firstname" required v-model="state.formData.user_firstname" :disabled="disabled" class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:max-w-xs sm:text-sm disabled:bg-gray-100" />
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="last-name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Nachname</label>
                        <div class="mt-1 sm:col-span-2 sm:mt-0">
                            <input type="text" name="last-name" id="last-name" required :disabled="disabled" v-model="state.formData.user_lastname" class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:max-w-xs sm:text-sm disabled:bg-gray-100" />
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="email" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">E-Mail</label>
                        <div class="mt-1 sm:col-span-2 sm:mt-0">
                            <input id="user_email" name="user_email" type="email" required class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm disabled:bg-gray-100" :disabled="disabled" v-model="state.formData.user_email" />
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="user_street" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Strasse</label>
                        <div class="mt-1 sm:col-span-2 sm:mt-0">
                            <input type="text" name="user_street" id="user_street" v-model="state.formData.user_street" class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm disabled:bg-gray-100" :disabled="disabled" />
                        </div>
                    </div>
                    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="user_plz" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">PLZ</label>
                        <div class="mt-1 sm:col-span-2 sm:mt-0">
                            <input type="text" name="user_plz" id="user_plz" autocomplete="user_plz" v-model="state.formData.user_plz" class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:max-w-xs sm:text-sm disabled:bg-gray-100" :disabled="disabled" />
                        </div>
                    </div>
                    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="user_city" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Stadt</label>
                        <div class="mt-1 sm:col-span-2 sm:mt-0">
                            <input type="text" name="user_city" id="user_city" v-model="state.formData.user_city" autocomplete="address-level2" class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:max-w-xs sm:text-sm disabled:bg-gray-100" :disabled="disabled" />
                        </div>
                    </div>


                    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="user_land" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Land</label>
                        <div class="mt-1 sm:col-span-2 sm:mt-0">
                            <select id="user_land" name="user_land" autocomplete="user_land" v-model="state.formData.user_land" class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:max-w-xs sm:text-sm" :disabled="disabled">
                                <option value="Schweiz">Schweiz</option>
                                <option value="Deutschland">Deutschland</option>
                                <option value="Österreich">Österreich</option>
                            </select>
                        </div>
                    </div>


                    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="user_land" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Role</label>
                        <div class="mt-1 sm:col-span-2 sm:mt-0">
                            <select id="user_roles" name="user_roles" autocomplete="user_roles" v-model="user_roles" class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:max-w-xs sm:text-sm" :disabled="disabled">
                                <option value="false" selected="selected">Rolle auswählen</option>
                                <option v-for="role in avail_user_roles" v-bind:key="role.id" :value="role.level">{{ role.name }}</option>
                            </select>

                        </div>
                    </div>
                    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="user_mobile" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Telefon</label>
                        <div class="mt-1 sm:col-span-2 sm:mt-0">
                            <input type="tel" name="user_mobile" id="user_mobile" autocomplete="user_mobile" v-model="state.formData.user_mobile" class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:max-w-xs sm:text-sm disabled:bg-gray-100" :disabled="disabled" />
                        </div>
                    </div>
                    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="user_password" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Passwort</label>
                        <div class="mt-1 sm:col-span-2 sm:mt-0">
                            <input type="password" name="user_password" id="user_password" autocomplete="user_password" v-model="state.formData.user_password" class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:max-w-xs sm:text-sm disabled:bg-gray-100" :disabled="disabled" required />
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="user_password_repeat" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Passwort wiederholen</label>
                        <div class="mt-1 sm:col-span-2 sm:mt-0">
                            <input type="password" name="user_password_repeat" id="user_password_repeat" autocomplete="user_password_repeat" v-model="state.formData.user_password_repeat" class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:max-w-xs sm:text-sm disabled:bg-gray-100" :disabled="disabled" required />
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="pt-5">
            <div class="flex justify-end">
                <button type="submit" class="ml-3 inline-flex justify-center rounded-md border border-transparent bg-primary-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 disabled:bg-gray-100" :disabled="disabled">
                    <LoadingSpinner size="4" color="fill-primary-600" v-if="state.spinner" background-dark="dark:text-gray-100"></LoadingSpinner> Speichern
                </button>

            </div>
        </div>
    </form>
    <div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-start px-4 py-6 sm:items-start sm:p-6 z-10">
        <MainToast :mode="state.message.messagemode" v-if="state.message.show">{{ state.message.message }}</MainToast>
    </div>

    <main-modal :open="state.openmodal" title="Benutzer angelegt" button="Zurück" @open="getModalStatus">
        <h3>User Passwort</h3>
        {{ state.modaldata.user_pass }}
    </main-modal>
</template>
<script setup>
import { ref, reactive, onBeforeMount } from 'vue';
import LoadingSpinner from '@/core/components/UI/Loading-Spinner.vue';
import MainToast from '@/core/components/UI/Main-Toast.vue';
import MainModal from '@/core/components/UI/Main-Modal.vue';
import { Message, ErrorLog } from '@/core/var/Message'
import { LoadRoles } from '@/core/var/user';
import getAuth from '@/core/var/auth';
import Simplestore from '@/core/var/Simplestore';
import axios from 'axios';
import { useAuthStore } from '@/core/store/AuthStore';


const auth = useAuthStore();
const userdata = auth.getUserData;

const disabled = ref(true);


const user_roles = ref(false);
const avail_user_roles = ref(false);
const state = reactive({
    formData: { user_land: 'Schweiz', user_roles: 'false' },
    spinner: false,
    servererror: false,
    userpass: '',
    openmodal: false,
    modaldata: false,
    message: {
        show: false,
        message: 'Ein schwerer Fehler ist aufgetreten, bitte versuchen Sie es erneut',
        messagemode: 'error'
    },
});


const user_channel = ref(process.env.VUE_APP_NEURAXIS_CHANNEL_ID);

onBeforeMount(async () => {
    Simplestore.spinner = true
    await getAuth()
    const res = await LoadRoles(auth.getUserRole)
    avail_user_roles.value = res
    Simplestore.spinner = false
    disabled.value = false
});


const getModalStatus = val => {
    state.openmodal = val;
}

const openModal = () => {
    state.openmodal = true;
}

const AddUser = async () => {
    
    if(user_roles.value == null || user_roles.value == false){
        Message('Eine Rolle muss angegeben werden', 'error', state);
        return false;
    }
    
    const payload =
    {
        "user_email": state.formData.user_email,
        "user_firstname": state.formData.user_firstname,
        "user_lastname": state.formData.user_lastname,
        "user_mobile": state.formData.user_mobile,
        "user_active": true,
        "user_channel": user_channel.value,
        "user_roles": [parseInt(user_roles.value)],
        "user_street": state.formData.user_street,
        "user_land": state.formData.user_land,
        "user_city": state.formData.user_city,
        "user_plz": state.formData.user_plz,
        "user_pw": state.formData.user_password
    }
    let pw_error = false;
    if (state.formData.user_password !== state.formData.user_password_repeat) {
        pw_error = true;
        Message('Diese Passwörter passen nicht zueinander', 'error', state);
        state.spinner = false;
        disabled.value = false;
        Simplestore.spinner = false;
        return false;
    }


    if (payload.user_email && pw_error == false) {

        state.spinner = true;
        disabled.value = true;
        Simplestore.spinner = true;
        await axios
            .post("/dashboard/create-user", payload)
            .then((response) => {
                //console.log(response.data);
                if (response.data === false) {
                    Message('Bei der Datenverarbeitung ist ein Fehler aufgetreten', 'error', state);
                    state.spinner = false;
                    Simplestore.spinner = false;
                    disabled.value = false;
                } else {

                    if (response.data.message && response.data.message === 'error') {
                        Message('Bei der Datenverarbeitung ist ein Fehler aufgetreten', 'error', state);
                        state.spinner = false;
                        disabled.value = false;
                        Simplestore.spinner = false;
                    } else if (response.data.message && response.data.message === 'exists') {
                        Message('Diese Mailadresse darf nicht verwendet werden, da sie bereits existiert', 'error', state);
                        state.spinner = false;
                        disabled.value = false;
                        Simplestore.spinner = false;
                    }
                     else if (response.data.message && response.data.message === 'noauth') {
                    Message('Problem bei der Authentifizerung. Bitte einem Dev Bescheid geben', 'error', state);
                    state.spinner = false;
                    disabled.value = false;
                    Simplestore.spinner = false;
                }
                    else {
                        state.userpass = response.data.pass;
                        state.spinner = false;
                        disabled.value = false;
                        state.message.messagemode = 'success';
                        state.message.message = `Ihre Daten wurden erfolgreich gespeichert.`;
                        state.message.show = true;
                        Simplestore.spinner = false;
                        state.formData = { user_land: 'Schweiz', user_roles: 'false' }
                        window.setTimeout(() => {
                            state.message.show = false;
                        }, 8000)
                    }
                }

            })
            .catch(function (error) {
                state.message.show = true;
                ErrorLog(error);
                window.setTimeout(() => {
                    state.spinner = false
                    state.message.show = false;
                    disabled.value = false;
                    Simplestore.spinner = false;
                }, 3000)


            })
    }
}
</script>