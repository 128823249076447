<script setup>
import { ref, defineProps, defineEmits } from 'vue'
import MenuButton from './Components/Menu-Button.vue'
import ModalButtons from './Components/Modal-Menue.vue'
import DataView from './Components/Data-View.vue'
import { isValidURL } from '@/core/var/tools'

// local variables
const editHtmlDialog = ref(false)
const BLOCK_NAME = "youtube_url";
const NAME = "Youtube Video";

// define props
const props = defineProps({
    template_type: { type: String, required: true },
    index: { type: Number, required: false },
    data: { type: Object, required: true },
    workflow_id: { type: String, required: false },
    image_library: { type: Object, required: false },
})
// define emits
const emits = defineEmits(['blockCallback'])

// fields
const text_video_url = ref(props.data.text_video_url)
const template = ref(BLOCK_NAME)

// functions
const edit_block = () => {
    editHtmlDialog.value = true
}

const save_block = () => {
    emits('blockCallback',
        {
            "template_data": {
                "template": template.value,
                "text_video_url": text_video_url.value,
            },
            "action": "save",
            "index": props.index
        }
    )
    editHtmlDialog.value = false
}

const delete_block = () => {
    emits('blockCallback',
        {
            "action": "delete",
            "index": props.index
        }
    )
    editHtmlDialog.value = false
}

const add_block = () => {
    emits('blockCallback',
        {
            "template_data": {
                "template": template.value,
                "text_video_url": "",
            },
            "action": "add",
            "index": props.index
        }
    )
}

const toggleDialog = () =>{
    editHtmlDialog.value = false
}
function convertYouTubeURL(url) {
    const baseEmbedURL = 'https://www.youtube.com/embed/';
    const videoId = url.split('v=')[1]; 
    const ampersandPosition = videoId.indexOf('&');
    if (ampersandPosition != -1) {
        return baseEmbedURL + videoId.substring(0, ampersandPosition);
    }
    return baseEmbedURL + videoId;
}

</script>

<template>
    <!--------------------------------------------------->
    <!------------------ Menu View ---------------------->
    <!--------------------------------------------------->
    <MenuButton v-if="props.template_type == 'menu'" :add_block="add_block" button="pi-video" :name="NAME" />

    <!--------------------------------------------------->
    <!------------------ Data View ---------------------->
    <!--------------------------------------------------->
    <DataView v-if="props.template_type == 'data'" :edit_block="edit_block" :name="NAME">
        <div v-if="isValidURL(text_video_url)" class="flex justify-center items-center p-6 m-4">
        <iframe width="560" height="315" :src="convertYouTubeURL(text_video_url)" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
        <div v-else class="h-24 flex justify-center items-center">
            Select video file
        </div>
    </DataView>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="editHtmlDialog" :style="{ width: '80%' }" header="Block bearbeiten" :modal="true" class="p-fluid">
        <div class="">
            <ModalButtons :saveBlock="save_block" :deleteBlock="delete_block" :toggleDialog="toggleDialog" />
            <div class="my-3">
                <InputText v-model="text_video_url" class="w-full" placeholder="https://" />
            </div>
        </div>
    </Dialog>
</template>